import React from "react"
import { useTranslation } from "react-i18next"

import BasePrice from "../../Base/Price"
import BaseTitle from "../../Base/Title"
import BaseDescription from "../../Base/Description"

const RoomPrices = ({ prices, subtitle = "", offer = null, className }) => {
  const { t } = useTranslation()
  const roomPrices = prices.map((price, index) => (
    <BasePrice
      title={t(`room.priceLabel.${price.id}`)}
      amount={price.amount}
      key={price.id}
      subtitle={`${
        price.id === "family"
          ? `${t("room.priceLabel.familySubtitle")}`
          : price.offer
          ? offer
          : ""
      }`}
      className={index < prices.length - 1 ? "mb-4" : ""}
    ></BasePrice>
  ))
  return (
    <div className={className}>
      <BaseTitle
        title={t("room.priceLabel.title")}
        className="mb-4 text-center"
      ></BaseTitle>
      {!!subtitle && (
        <BaseDescription
          description={subtitle}
          className="text-center font-semibold underline mb-4"
        ></BaseDescription>
      )}
      {roomPrices}
      <BaseDescription
        description={`+ ${t("room.priceLabel.tax")}`}
        className="mt-4"
      />
    </div>
  )
}

export default RoomPrices
